import { Sanity, SanityAPIRequests } from '@bounty/common'
import useSWR, { SWRResponse } from 'swr'
import { SANITY_DATASET, SANITY_PROJECT_ID } from '../config/env'
import { CreatorsAPIRequests, CreatorsClient } from '../libs/creators'
import { logger } from '../utils/logger'

export const useSWRSanity = <K extends keyof SanityAPIRequests>(
  method: K,
  ...params: SanityAPIRequests[K] extends (...args: infer P) => any
    ? P
    : [undefined?]
): SWRResponse<Awaited<ReturnType<SanityAPIRequests[K]>>> => {
  const fetcher = () => {
    const sanity = new Sanity(
      SANITY_PROJECT_ID,
      SANITY_DATASET,
      'marketingSite',
    )

    // @ts-expect-error typescript's inference can't keep up with the vibe unfortunately
    return sanity[method](...params)
  }
  const response = useSWR(Sanity.produceCacheKey(method, ...params), fetcher)

  if (response.error) {
    logger.error(response.error)
    throw new Error(response.error)
  }

  return response
}

export const useSWRBounty = <K extends keyof CreatorsAPIRequests>(
  method: K,
  ...params: CreatorsAPIRequests[K] extends (...args: infer P) => any
    ? P
    : [undefined?]
): SWRResponse<Awaited<ReturnType<CreatorsAPIRequests[K]>>> => {
  const fetcher = () => {
    const client = new CreatorsClient()

    // @ts-expect-error typescript's inference can't keep up with the vibe unfortunately
    return client[method](...params)
  }
  const response = useSWR(
    CreatorsClient.produceCacheKey(method, ...params),
    // @ts-expect-error typescript's inference can't keep up with the vibe unfortunately
    fetcher,
  )

  if (response.error) {
    logger.error(response.error)
    throw new Error(response.error)
  }

  // @ts-expect-error typescript's inference can't keep up with the vibe unfortunately
  return response
}
