import { useQuery } from '@apollo/client'
import {
  Link,
  SkeletonLoader,
  Stat,
  StatNumber,
  StatTitle,
  XStack,
  YStack,
} from '@bounty/creators-design-system'
import { prettyCurrency } from '@bounty/utils'
import { CurrentRewardsDocument } from '../../../generated/backendGraphql'
import { IoIosArrowDroprightCircle } from 'react-icons/io'

export const CurrentRewards = () => {
  const { data, loading } = useQuery(CurrentRewardsDocument)

  if (loading || !data) return <SkeletonLoader />
  const pendingPayments = data.userPaymentSummary?.pendingPayments ?? 0
  const availableBalance = data.userPaymentSummary?.availableBalance ?? 0

  return (
    <XStack space="$4">
      <Link
        event="Home Current Rewards Tile Clicked"
        flexGrow={1}
        href="/wallet/overview"
      >
        <Stat flexGrow={1} data-testid="currentRewardsTile">
          <XStack alignItems="center" justifyContent="space-between">
            <YStack>
              <StatTitle>My wallet</StatTitle>
              <StatNumber>
                {prettyCurrency(pendingPayments + availableBalance, {
                  precision: 2,
                })}
              </StatNumber>
            </YStack>
            <IoIosArrowDroprightCircle color="white" size="20" />
          </XStack>
        </Stat>
      </Link>
      {/* <Stat flexGrow={1}>
          <StatTitle>New Bounties</StatTitle>
          <StatNumber>18</StatNumber>
        </Stat> */}
    </XStack>
  )
}
