import axios, { AxiosError, AxiosInstance } from 'axios'
import {
  GetFiltersResponse,
  GetStorePayoutResponse,
  GetStoreResponse,
  GetStoresCountResponse,
  GetStoresResponse,
  GetTopVideosResponse,
  GiftPreviewResponse,
  RejectOfferRestResponse,
  RetailPreviewResponse,
  TikTokAuthNativeResponse,
} from '@bounty/types'
import { API_URL } from '../config/env'
import { z } from 'zod'
import {
  GetStoreParams,
  GetStoresParams,
  GetStorePayoutParams,
  TikTokAuthNativeBody,
} from '@bounty/schemas'
import {
  SignupOrderPreviewBody,
  SignupOrderPreviewResponse,
} from '@bounty/common'
import { logger } from '../utils/logger'

/**
 * Sanity requests that directly result in API calls. Used for
 * SWR caching.
 */
export type CreatorsAPIRequests = Omit<
  InstanceType<typeof CreatorsClient>,
  'httpClient' | 'produceCacheKey'
>

export class CreatorsClient {
  httpClient: AxiosInstance

  constructor() {
    this.httpClient = axios.create({
      baseURL: `${API_URL}/api/v1`,
    })
  }

  /**
   * Products a cache friendly SWR key for use at getStaticProps. Params must be a serializable object like
   * entity https://swr.vercel.app/docs/arguments#passing-objects
   */

  static produceCacheKey<K extends keyof CreatorsAPIRequests>(
    methodName: K,
    ...params: CreatorsAPIRequests[K] extends (...args: infer P) => any
      ? P
      : [undefined?]
  ) {
    return JSON.stringify([`CREATORS__${methodName}`, ...params])
  }

  async handleNativeTikTokAuth(body: TikTokAuthNativeBody) {
    // This api isn't versioned like all the others...
    const resp = await axios.post<TikTokAuthNativeResponse>(
      `${API_URL}/auth/tik-tok-native`,
      body,
    )

    if (resp.data.success === false) {
      throw new Error(resp.data.code)
    }

    return resp.data
  }

  async getStorefrontFilters() {
    const resp = await this.httpClient.get<GetFiltersResponse>(
      `/storefront/filters`,
    )

    if (resp.data.success === false) {
      throw new Error(resp.data.message)
    }

    return resp.data.data
  }

  async computePayoutForStore(
    slug: string,
    params: z.input<typeof GetStorePayoutParams>,
  ) {
    const resp = await this.httpClient
      .get<GetStorePayoutResponse>(`/storefront/${slug}/payouts`, { params })
      .catch((err) => {
        logger.log(err)
        return
      })

    if (!resp) {
      return
    }

    if (resp.data.success === false) {
      logger.log(resp.data.message)
      return
    }

    return resp.data.data
  }

  async getStoresCount() {
    const resp = await this.httpClient.get<GetStoresCountResponse>(
      `/storefront/count`,
    )

    if (resp.data.success === false) {
      throw new Error(resp.data.message)
    }

    return resp.data.data
  }

  async getStores(params?: z.input<typeof GetStoresParams>) {
    const resp = await this.httpClient.get<GetStoresResponse>(`/storefront`, {
      params,
    })

    if (resp.data.success === false) {
      throw new Error(resp.data.message)
    }

    return resp.data.data
  }

  async getStore(slug: string, params: z.input<typeof GetStoreParams>) {
    try {
      const resp = await this.httpClient.get<GetStoreResponse>(
        `/storefront/${slug}`,
        {
          params,
        },
      )

      return resp.data.success
        ? {
            success: true,
            data: resp.data.data,
          }
        : {
            success: false,
            code: resp.data.code,
          }
    } catch (error) {
      const axiosError = error as AxiosError<GetStoreResponse>

      return {
        success: false,
        code: axiosError.response?.data.code,
      }
    }
  }

  async getTopVideos() {
    const resp = await this.httpClient.get<GetTopVideosResponse>(
      `/videos/top-videos`,
    )

    if (resp.data.success === false) {
      throw new Error(resp.data.message)
    }

    return resp.data.data
  }

  async getOrderInfoPreview(orderId: string) {
    // TODO: This should pull back minimum payout and conditionally take a user ID to
    // see if eligible?
    // TODO: Needs to return the store's slug for when a user lands here with no eligible products
    // This api isn't versioned like all the others...
    const resp = await axios.post<SignupOrderPreviewResponse>(
      `${API_URL}/signup/order-info-preview`,
      {
        orderId,
        views: 1000,
      } as SignupOrderPreviewBody,
    )

    if (resp.data.success === false) {
      throw new Error(resp.data.message)
    }

    return resp.data
  }

  async giftReject(key: string) {
    // This api isn't versioned like all the others...
    const resp = await axios.post<RejectOfferRestResponse>(
      `${API_URL}/reject-offer/${key}`,
    )

    if (resp.data.success === false) {
      throw new Error(resp.data.code)
    }

    return resp.data
  }

  /**
   * Does not return a success flag so you need to handle everything inside of the template
   */
  async giftPreview(key: string) {
    // This api isn't versioned like all the others...
    const resp = await axios.get<GiftPreviewResponse>(
      `${API_URL}/gift-invite/${key}/preview`,
    )

    return resp.data
  }

  async retailPreview(storeId: string) {
    const resp = await this.httpClient.get<RetailPreviewResponse>(
      `/retail/${storeId}/preview`,
    )

    return resp.data
  }
}
