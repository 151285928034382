import {
  getTokens,
  H1,
  Paragraph,
  YStack,
} from '@bounty/creators-design-system'
import { ImageCarousel } from '../../../components/ImageRoll/ImageCarousel'
import { ImageRolls } from '../../../components/ImageRoll/ImageRoll'

const tokens = getTokens({ prefixed: false })

export const Hero = () => {
  return (
    <YStack
      data-testid="unauthedHeroSection"
      position="relative"
      overflow="hidden"
      pb="$8"
      backgroundColor={'$primary.500'}
      mb="$10"
      $gtMd={{
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      {/* <LinearGradient
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        colors={['#3A3BC5', '#484AF4']}
        locations={[0.26, 0.68]}
        start={{ x: 1.048977006733651, y: 0.5612865944226898 }}
        end={{ x: -0.04897700673365102, y: 0.4387134055773102 }}
      /> */}
      <YStack
        width="100%"
        maxWidth={1300}
        alignSelf="center"
        $gtMd={{
          flexDirection: 'row',
          mt: -1 * tokens.size[9].val,
          width: '100%',
          // height: 373,
          mx: 0,
          pb: 0,
        }}
      >
        <YStack
          px="$4"
          py="$7"
          $gtMd={{ paddingTop: 103, paddingLeft: 71, paddingRight: 101 }}
          flex={0}
        >
          <H1
            size="$2xl"
            width="100%"
            $gtMd={{ size: '$5xl', mb: '$5', width: '100%' }}
            zIndex={5}
            color="$neutral.50"
            mb="$1"
          >
            Partner with your favorite brands, instantly
          </H1>
          <Paragraph
            size="$sm"
            $gtMd={{ size: '$2xl', width: '100%' }}
            color="$neutral.50"
            width="100%"
          >
            Shop and share what you love. Get paid every time, and build
            relationships with your favorite brands.
          </Paragraph>
        </YStack>
        <YStack $gtMd={{ display: 'none' }}>
          <ImageCarousel imageWidth={200} />
        </YStack>
        <YStack
          display="none"
          $gtMd={{ display: 'flex' }}
          width={350}
          height={373}
          flexShrink={0}
          pr="$6"
        >
          <ImageRolls
            width={375}
            height={373}
            overflowMultiplier={1}
            numberOfColumns={2}
          />
        </YStack>
      </YStack>
    </YStack>
  )
}
