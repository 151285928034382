import {
  BulbIcon,
  H5,
  Paragraph,
  XStack,
  YStack,
} from '@bounty/creators-design-system'
import { HiStar } from 'react-icons/hi'
import { IoCloseCircle } from 'react-icons/io5'
import { PiSealCheckFill } from 'react-icons/pi'

export const VideoRequirements = () => {
  const requirementsList = [
    {
      icon: HiStar,
      text: 'Focus on the product',
    },
    {
      icon: IoCloseCircle,
      text: 'No filters or effects',
    },
    {
      icon: BulbIcon,
      text: 'Great lighting + clear audio',
    },
    {
      icon: PiSealCheckFill,
      text: 'Clearly show brand’s logo',
    },
  ]

  return (
    <YStack padding="$4" bg="$primary.100" borderRadius="$6">
      <H5 mb="$2">Post Requirements</H5>
      <YStack>
        {requirementsList.map(({ icon: Icon, text }) => (
          <XStack display="flex" alignItems="center">
            <XStack
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="$primary.800"
              width="$5"
              height="$5"
              borderRadius="50%"
              mr="$2"
            >
              {<Icon color="white" />}
            </XStack>
            <Paragraph>{text}</Paragraph>
          </XStack>
        ))}
      </YStack>
    </YStack>
  )
}
