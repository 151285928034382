import { Page, Section } from '@bounty/creators-design-system'
import { Hero } from './components/Hero'
import { Storefront } from './components/Storefront'
import { HowItWorks } from './components/HowItWorks'

export type CreatorsHomeScreenProps = unknown

export function HomeComponentNotAuthed() {
  return (
    <Page
      space="$6"
      scroll
      framed={false}
      containerSize="fluid"
      addTopSpacing={false}
      addHorizontalSpacing={false}
      // addBottomSpacing={false}
    >
      <Hero />
      <Section>
        <Storefront title="Shop" />
      </Section>
      <HowItWorks />
    </Page>
  )
}
