import {
  Image,
  XStack,
  getTokens,
  YStackProps,
} from '@bounty/creators-design-system'
import { generateImages } from './ImageRoll.images'
import { useState } from 'react'

const videoAspectRatio = 1.72
const spacing: keyof ReturnType<typeof getTokens>['space'] = 6

const useImages = ({ imageWidth: containerWidth }: { imageWidth: number }) => {
  const { space } = getTokens({ prefixed: false })

  // Rerenders will cause these to jank
  // Take 20 random images
  const [images] = useState(generateImages(1)[0].slice(0, 20))

  const spacingValue = space[spacing]['val']
  const columnWidth = containerWidth
  const columnWidthMinusSpacing = columnWidth - spacingValue

  const width = Math.floor(columnWidthMinusSpacing)
  const height = Math.floor(columnWidthMinusSpacing * videoAspectRatio)

  // Hacky shim so that this carousel stops with images
  const carouselEndingX =
    -Number((width + spacingValue) * images.length - containerWidth) + width * 4

  return {
    containerWidth,
    images,
    imageProps: {
      width,
      height,
    },
    carouselEndingX,
  }
}

export const ImageCarousel = ({
  imageWidth,
  ...rest
}: YStackProps & {
  imageWidth: number
}) => {
  const { images, imageProps, carouselEndingX } = useImages({
    imageWidth,
  })

  return (
    <XStack
      key={carouselEndingX}
      space={`$${spacing}`}
      flexGrow={1}
      position="relative"
      alignSelf="stretch"
      animation="molasses"
      enterStyle={{
        x: 0,
      }}
      x={carouselEndingX}
      {...rest}
    >
      {images.map((image, index) => {
        return (
          <Image
            key={index}
            src={image}
            borderRadius="$2"
            resizeMode="cover"
            borderWidth={1}
            borderColor={'$neutral.50'}
            {...imageProps}
          />
        )
      })}
    </XStack>
  )
}
