import { useQuery } from '@apollo/client'
import {
  Heading,
  LinkButton,
  Paragraph,
  SkeletonLoader,
  XStack,
  YStack,
  Image,
  Text,
  DarkPurpleGradient,
} from '@bounty/creators-design-system'
import { ConnectSocialsDocument } from '../../../generated/backendGraphql'
import TikTokLogo from '../../../assets/tiktok-app-icon-logo.png'
import InstagramLogo from '../../../assets/instagram-2.png'

export const ConnectSocials = () => {
  const { data, loading } = useQuery(ConnectSocialsDocument)

  if (loading || !data) return <SkeletonLoader />
  if (
    data.tikTokProfile?.verified === true &&
    data.instagramProfile?.verified === true
  ) {
    return null
  }

  return (
    <XStack
      px="$4"
      py="$4"
      borderRadius="$4"
      justifyContent="space-between"
      display="flex"
      alignItems={'flex-end'}
      flex={0}
    >
      <YStack zIndex={1} flexShrink={1} flex={1}>
        <Heading size={'$md'} color="$neutral.50" fontWeight="$bold" mb="$1">
          Connect your accounts
        </Heading>
        <Paragraph size={'$sm'} color="$neutral.50" mb="$4">
          Connect both for maximum opportunities!
        </Paragraph>
        <YStack zIndex={1} space={'$2'}>
          {!data.tikTokProfile?.verified && (
            <LinkButton
              display="flex"
              justifyContent="flex-start"
              href="/verify-tiktok"
              event={{
                eventName: 'Home Page Creators Connect TikTok Clicked',
              }}
              variant="ghost"
              backgroundColor="$neutral.50"
            >
              <Image src={TikTokLogo} width={24} height={24} />
              <Text fontWeight={'$semibold'} color="$primary.500" px="$1">
                Connect TikTok
              </Text>
            </LinkButton>
          )}
          {!data.instagramProfile?.verified && (
            <LinkButton
              display="flex"
              justifyContent="flex-start"
              href="/verify-instagram"
              event={{
                eventName: 'Home Page Creators Connect Instagram Clicked',
              }}
              backgroundColor="$neutral.50"
              variant="ghost"
            >
              <Image src={InstagramLogo} width={24} height={24} />
              <Text fontWeight={'$semibold'} color="$primary.500" px="$1">
                Connect Instagram
              </Text>
            </LinkButton>
          )}
        </YStack>
      </YStack>
      <DarkPurpleGradient
        borderRadius="$4"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
      />
    </XStack>
  )
}
