import { LinearGradient, LinearGradientProps } from '@tamagui/linear-gradient'

export type DarkPurpleGradientProps = LinearGradientProps

export const DarkPurpleGradient = (props: DarkPurpleGradientProps) => {
  return (
    <LinearGradient
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      colors={['#3A3BC5', '#484AF4']}
      locations={[0.26, 0.68]}
      start={{ x: 1.048977006733651, y: 0.5612865944226898 }}
      end={{ x: -0.04897700673365102, y: 0.4387134055773102 }}
      {...props}
    />
  )
}
