import { HomeScreen } from '@bounty/creators-common/screens/home'
import { GetStaticPropsWithSEO } from '@bounty/creators-common/types'
import { withSEO } from '../withSEO'
import { CreatorsClient } from '@bounty/creators-common/libs/creators'

import { ReactElement } from 'react'
import { Layout } from '../Layout'
import { NextPageWithLayout } from './_app'

export const getStaticProps: GetStaticPropsWithSEO = async () => {
  const shopsResp = await new CreatorsClient().getStores()

  return {
    props: {
      fallback: {
        [CreatorsClient.produceCacheKey('getStores')]: shopsResp,
      },
      seo: {
        title: 'Bounty | Get Paid to Post on TikTok and Instagram',
        description: `Get paid for sharing product you love on TikTok and Instagram. It’s the easiest way to get paid as a creator.`,
      },
    },
  }
}

const Page: NextPageWithLayout = withSEO(HomeScreen)

Page.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>
}

export default Page
