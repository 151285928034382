import { YStack, YStackProps, Spinner } from 'tamagui'

export type SkeletonLoaderProps = YStackProps

export const SkeletonLoader = ({ ...rest }: SkeletonLoaderProps) => {
  return (
    <YStack height={200} justifyContent="center" alignItems="center" {...rest}>
      <Spinner color="$primary.500" size="large" />
    </YStack>
  )
}
