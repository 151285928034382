import { RequireWelcomeModal } from '../../components/RequireWelcomeFlow/RequireWelcomeModal'
import { RequireUpdatedTermsModal } from '../../components/RequireUpdatedTerms/RequireUpdatedTermsModal'
import { useAuth } from '../../hooks'
import { HomeComponentAuthed } from './HomeComponent.Authed'
import { HomeComponentNotAuthed } from './HomeComponent.NotAuthed'

export type CreatorsHomeScreenProps = unknown

export function HomeComponent(_props: CreatorsHomeScreenProps) {
  const [{ isInitializing, isAuthed }] = useAuth()

  // TODO: When authed, causes a flash of unauthenticated content, but keeps prerendering working
  // need to find a nicer solution here
  return isInitializing === false && isAuthed ? (
    <RequireWelcomeModal>
      <RequireUpdatedTermsModal>
        <HomeComponentAuthed />
      </RequireUpdatedTermsModal>
    </RequireWelcomeModal>
  ) : (
    <HomeComponentNotAuthed />
  )
}
