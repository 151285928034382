import {
  Heading,
  XStack,
  YStack,
  LinkButton,
  DarkPurpleGradient,
} from '@bounty/creators-design-system'

export const RetailCallout = () => {
  return (
    <XStack flex={1} space="$4">
      <YStack
        space="$4"
        px="$4"
        py="$6"
        flex={1}
        alignItems="flex-start"
        zIndex={1}
      >
        <Heading flex={1} size="$md" color={'$whiteAlpha.900'}>
          Earn in-store by picking up select brands on your next shopping trip!
        </Heading>
        <LinkButton
          event="Retail Callout Clicked"
          variant="ghost"
          backgroundColor={'$whiteAlpha.900'}
          href="/shop/tags/in-store"
        >
          Shop participating brands
        </LinkButton>
      </YStack>
      <DarkPurpleGradient
        borderRadius="$4"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
      />
    </XStack>
  )
}
