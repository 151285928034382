/* eslint-disable react/forbid-elements */
import {
  GiftIcon,
  MoneyIcon,
  Paragraph,
  ShopIcon,
  Text,
  useMedia,
  XStack,
  YStack,
} from '@bounty/creators-design-system'
import HowItWorksCreators from '../../../assets/creators-how-it-works.png'

export const HowItWorks = () => {
  const { gtMd } = useMedia()

  return (
    <YStack
      px="$4"
      $gtXs={{ flexDirection: 'row-reverse' }}
      width="100%"
      maxWidth={1300}
      alignSelf="center"
    >
      <YStack
        px="$6"
        pt="$14"
        backgroundColor={'$primary.500'}
        borderTopLeftRadius="$7"
        borderTopRightRadius={'$7'}
        alignItems="center"
        $gtXs={{
          flex: 1,
          flexBasis: 0,
          borderTopLeftRadius: 0,
          borderBottomRightRadius: '$7',
          alignItems: 'center',
        }}
      >
        <img
          alt="Bounty app creator table"
          loading="lazy"
          src={HowItWorksCreators}
          style={{ maxWidth: '310px' }}
        />
      </YStack>
      <YStack
        backgroundColor={'$neutral.100'}
        borderBottomLeftRadius="$7"
        borderBottomRightRadius={'$7'}
        px="$6"
        py="$8"
        space="$8"
        borderLeftWidth={1}
        borderRightWidth={1}
        borderBottomWidth={1}
        borderColor="$blackAlpha.50"
        $gtXs={{
          flex: 1,
          flexBasis: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: '$7',
          borderBottomLeftRadius: '$7',
          borderRightWidth: 0,
          borderTopWidth: 1,
          justifyContent: 'center',
        }}
      >
        <XStack space="$4">
          <XStack>
            <ShopIcon size={gtMd ? 32 : 28} weight="fill" />
          </XStack>
          <XStack flexShrink={1}>
            <Paragraph
              fontFamily="$heading"
              color="$primary.800"
              $gtMd={{
                fontSize: '$2xl',
                lineHeight: '$2xl',
              }}
            >
              <Text
                fontFamily={'$heading'}
                color="$primary.500"
                $gtMd={{
                  fontSize: '$2xl',
                  lineHeight: '$2xl',
                }}
              >
                Shop
              </Text>{' '}
              your favorite brands and partner instantly
            </Paragraph>
          </XStack>
        </XStack>
        <XStack space="$4">
          <XStack>
            <GiftIcon size={gtMd ? 32 : 28} weight="fill" />
          </XStack>
          <XStack flexShrink={1}>
            <Paragraph
              fontFamily="$heading"
              color="$primary.800"
              $gtMd={{
                fontSize: '$2xl',
                lineHeight: '$2xl',
              }}
            >
              <Text
                fontFamily={'$heading'}
                color="$primary.500"
                $gtMd={{
                  fontSize: '$2xl',
                  lineHeight: '$2xl',
                }}
              >
                Receive gifts
              </Text>{' '}
              and{' '}
              <Text
                fontFamily={'$heading'}
                color="$primary.500"
                $gtMd={{
                  fontSize: '$2xl',
                  lineHeight: '$2xl',
                }}
              >
                offers
              </Text>{' '}
              from brands who love your content
            </Paragraph>
          </XStack>
        </XStack>
        <XStack space="$4">
          <XStack>
            <MoneyIcon size={gtMd ? 32 : 28} weight="fill" />
          </XStack>
          <XStack flexShrink={1}>
            <Paragraph
              fontFamily="$heading"
              color="$primary.800"
              $gtMd={{
                fontSize: '$2xl',
                lineHeight: '$2xl',
              }}
            >
              <Text
                fontFamily={'$heading'}
                color="$primary.500"
                $gtMd={{
                  fontSize: '$2xl',
                  lineHeight: '$2xl',
                }}
              >
                Get paid
              </Text>{' '}
              for spark codes
            </Paragraph>
          </XStack>
        </XStack>
      </YStack>
    </YStack>
  )
}
