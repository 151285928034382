import {
  Button,
  H2,
  PageLoading,
  ScrollView,
  YStack,
  XStack,
  Link,
  Text,
} from '@bounty/creators-design-system'
import { ReactNode } from 'react'
import { StoreOverviewCard } from '../../../components/StoreOverviewCard'
import { useSWRBounty } from '../../../hooks/swr'

export type StorefrontProps = {
  title?: ReactNode
}

export const Storefront = ({ title }: StorefrontProps) => {
  const { data } = useSWRBounty('getStores')

  if (!data) {
    return <PageLoading />
  }

  return (
    <YStack width="100%" maxWidth={1300} alignSelf="center">
      <XStack pb="$4" alignItems="center" justifyContent="space-between">
        <H2 size="$xl" $gtMd={{ size: '$2xl' }}>
          {title}
        </H2>
        <Link event="Home Page View All Bounties Clicked" href="/shop">
          <Text fontWeight={'$semibold'} size="$xs">
            View All
          </Text>
        </Link>
      </XStack>
      <ScrollView space="$4" horizontal>
        {/* Stubbed so that the first item is flush with the header. Space above takes care of it */}
        {/* <XStack /> */}
        {data.items.map((shop) => {
          return (
            <StoreOverviewCard
              event={{
                eventName: 'Home Store Overview Tile Clicked',
                shopId: shop.id,
                shopUrl: shop.shopUrl,
                name: shop.name,
                startingCPM: shop.startingCPM,
                startingCPMViews: shop.startingCPMViews,
              }}
              key={shop.id}
              shop={shop}
            />
          )
        })}
      </ScrollView>
      <Link event="Home Page View All Bounties Clicked" href="/shop">
        <Button
          event="Home Page View All Bounties Clicked"
          mt="$2"
          variant="outline"
        >
          View all brands
        </Button>
      </Link>
    </YStack>
  )
}
