import {
  H2,
  Page,
  Text,
  XStack,
  PageLoading,
  Link,
  YStack,
  Heading,
  useMedia,
} from '@bounty/creators-design-system'
import BountyBrandmark from '../../assets/bounty-brandmark.png'
import { BountyCard } from '../../components/BountyCard/BountyCard'
import { useQuery } from '@apollo/client'
import { HomeDocument } from '../../generated/backendGraphql'
import { Storefront } from './components/Storefront'
import { RetailCallout } from './components/RetailCallout'
import { withAuth } from '../../components/RequireAuth'
import { CurrentRewards } from './components/CurrentRewards'
import { MinimumPayoutAdTile } from '../../components/MinimumPayoutAdTile'
import { useRouter } from 'solito/router'
import { ConnectSocials } from './components/ConnectSocials'
import { VideoRequirements } from './components/VideoRequirements'
import { CallToAction } from './components/CallToAction'

export const HomeComponentAuthed = withAuth(() => {
  const { data, loading } = useQuery(HomeDocument, {
    variables: { params: { skip: 0, take: 1 } },
  })
  const media = useMedia()
  const router = useRouter()

  if (loading || !data) return <PageLoading />

  if (data.recommendedBounties.length === 0) {
    return (
      <Page space="$6" scroll containerSize="sm" framed>
        <Heading size="$2xl">Welcome to Bounty</Heading>
        <ConnectSocials />
        <VideoRequirements />
        {media.gtMd && <CurrentRewards />}
        <Storefront title="Brands" />
      </Page>
    )
  }

  // only has one in-progress bounty and user is associated with the community
  const firstBounty = data.bounties.items[0]

  const showCTA =
    data.bounties.count === 1 &&
    data.currentUser.communities?.includes(firstBounty.store.id) &&
    ['PENDING', 'ACCEPTED', 'LIVE', 'COMPLETED', 'HOLD'].includes(
      firstBounty.status,
    )

  return (
    <Page space="$6" scroll containerSize="sm">
      <YStack space="$3">
        {showCTA && (
          <CallToAction
            storeLogoImgLink={firstBounty.store.logoImgLink ?? BountyBrandmark}
            storeName={firstBounty.store.name}
            onPress={() => router.push(`/bounties/${firstBounty.id}`)}
          />
        )}
        <ConnectSocials />
        <VideoRequirements />
        <XStack alignItems="center" justifyContent="space-between">
          <H2 size="$2xl">Bounties</H2>
          <Link event="Home Page View All Bounties Clicked" href="/bounties">
            <Text fontWeight={'$semibold'} size="$xs">
              View All
            </Text>
          </Link>
        </XStack>
        {data.recommendedBounties.map((bounty) => (
          <BountyCard key={bounty.id} bounty={bounty} />
        ))}
      </YStack>
      {media.gtMd && <CurrentRewards />}
      <MinimumPayoutAdTile />
      <Storefront title="Brands" />
      <RetailCallout />
    </Page>
  )
})
