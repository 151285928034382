import {
  Text,
  Heading,
  Card,
  Paragraph,
  Button,
  Avatar,
  CardProps,
} from '@bounty/creators-design-system'

type CallToActionProps = {
  storeLogoImgLink: string
  storeName: string
  onPress: () => void
  containerProps?: CardProps
}

export const CallToAction = ({
  storeLogoImgLink,
  storeName,
  onPress,
  containerProps,
}: CallToActionProps) => {
  return (
    <Card p="$4" space="$4" mb="$4" bordered {...containerProps}>
      <Avatar src={storeLogoImgLink} size={56} />
      <Heading size={'$xl'}>Welcome to our creator community!</Heading>
      <Paragraph>
        Let's start on your first post for{' '}
        <Text fontWeight={'$semibold'}>{storeName}</Text>, grab your favorite
        products to get started and be sure to follow the instructions so you
        can get paid.
      </Paragraph>
      <Button
        size="$lg"
        event="Community CTA Get Started Clicked"
        onPress={onPress}
      >
        Get started
      </Button>
    </Card>
  )
}
