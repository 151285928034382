import {
  EventType,
  H4,
  Image,
  Link,
  YStack,
} from '@bounty/creators-design-system'
import { GetStoresResponseSuccess } from '@bounty/types'

export type StoreOverviewCardProps = {
  shop: GetStoresResponseSuccess['data']['items'][0]
  event: EventType
}

export const StoreOverviewCard = ({ shop, event }: StoreOverviewCardProps) => {
  return (
    <Link href={`/shop/${shop.slug}`} event={event}>
      <YStack
        backgroundColor={'$neutral.300'}
        width="$40"
        height="$40"
        justifyContent="flex-end"
        p="$2"
        position="relative"
        overflow="hidden"
        borderRadius={'$2'}
        $gtXs={{
          width: '$48',
          height: '$48',
        }}
        $gtSm={{
          width: '$56',
          height: '$56',
        }}
        $gtMd={{
          width: '$56',
          height: '$56',
        }}
      >
        <Image
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          width="$40"
          height="$40"
          $gtXs={{
            width: '$48',
            height: '$48',
          }}
          $gtSm={{
            width: '$56',
            height: '$56',
          }}
          $gtMd={{
            width: '$56',
            height: '$56',
          }}
          src={shop.headerImgLinkPrimary!}
        />
        <YStack
          position="absolute"
          bottom={'$1'}
          left={'$1'}
          right={'$1'}
          backgroundColor="$neutral.50"
          borderRadius={'$2'}
          zIndex={1000}
        >
          <H4 numberOfLines={1} size="$md" px="$3" py="$1">
            {shop.name}
          </H4>
        </YStack>
        <YStack
          position="absolute"
          top={'$1'}
          right={'$1'}
          backgroundColor="$neutral.50"
          borderRadius={'$2'}
          zIndex={1000}
          width="fit-content"
        >
          <H4 numberOfLines={1} size="$xs" px="$3" py="$1">
            Featured
          </H4>
        </YStack>
      </YStack>
    </Link>
  )
}
