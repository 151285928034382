import { YStack, YStackProps } from 'tamagui'
import { createStateContext } from '../factory/createStateContext'
import { ColorScheme } from '../themes/utils'
import { H3, H3Props, Text } from './text'

const [Context] = createStateContext<{ colorScheme: ColorScheme }>({
  colorScheme: 'primary',
})

export type StatProps = YStackProps & { colorScheme?: ColorScheme }

export const Stat = ({
  children,
  colorScheme = 'primary',
  ...rest
}: StatProps) => {
  return (
    <Context initialValue={{ colorScheme }}>
      <YStack
        px="$4"
        py="$4"
        space="$1"
        borderRadius="$6"
        backgroundColor={`$${colorScheme}.500`}
        {...rest}
      >
        {children}
      </YStack>
    </Context>
  )
}

export type StatTitleProps = H3Props

export const StatTitle = ({ children, ...rest }: StatTitleProps) => {
  return (
    <H3 size="$xs" color={'$whiteAlpha.800'} {...rest}>
      {children}
    </H3>
  )
}

export type StatNumberProps = H3Props

export const StatNumber = ({ children, ...rest }: StatNumberProps) => {
  return (
    <Text
      size="$4xl"
      color={'$whiteAlpha.900'}
      fontFamily={'$heading'}
      {...rest}
    >
      {children}
    </Text>
  )
}
