import {
  Button,
  Dialog,
  Heading,
  Image,
  LinkButton,
  Paragraph,
  useAnalytics,
  XStack,
  YStack,
} from '@bounty/creators-design-system'
import { IfFeatureEnabled, useFeatureValue } from '@growthbook/growthbook-react'
import { useState } from 'react'

export const MinimumPayoutAdTile = () => {
  const [show, setShow] = useState(false)
  const { track } = useAnalytics()
  const tileConfig = useFeatureValue<{
    imgLink: string
    offer: string
    storeName: string
    storeUrl: string
  } | null>('home-page-ad-tile-config', null)

  if (!tileConfig) return null

  return (
    <IfFeatureEnabled feature="show-home-page-ad-tile">
      <XStack
        px="$4"
        py="$4"
        borderRadius="$4"
        backgroundColor="$primary.500"
        cursor={'pointer'}
        onPress={() => {
          track('Home Page Creators Minimum Payout Ad Tile Clicked', tileConfig)
          setShow(true)
        }}
      >
        <XStack
          display="flex"
          alignItems={'center'}
          justifyContent="space-between"
          flex={1}
        >
          <YStack flexShrink={1}>
            <Heading
              width={200}
              size={'$md'}
              color="$neutral.50"
              fontWeight="bold"
              mb="$2"
            >
              Earn {tileConfig.offer} when you post about {tileConfig.storeName}
            </Heading>
            <Button
              event={{
                eventName: 'Home Page Creators Minimum Payout Ad Tile Clicked',
                ...tileConfig,
              }}
              size="$xs"
              width={87}
              backgroundColor="$neutral.50"
              buttonTextProps={{ color: '$primary.900' }}
              colorScheme="whiteAlpha"
              onPress={() => {
                setShow(true)
              }}
            >
              Claim Offer
            </Button>
          </YStack>
          <XStack position={'relative'} flexShrink={0}>
            <Image
              height={100}
              width={100}
              borderRadius="$2"
              resizeMode={'cover'}
              alt="Collage of Bounty images"
              src={tileConfig.imgLink}
            />
          </XStack>
        </XStack>
      </XStack>
      <Dialog
        sheetProps={{ snapPoints: [40] }}
        open={show}
        onOpenChange={setShow}
      >
        <Heading mb="$4">Rules</Heading>
        <Paragraph size="$sm" mb="$6">
          Only the first Bounty posted about {tileConfig.storeName} is eligible,
          including historical posts. Must make an eligible product purchase
          from the storefront to earn.
        </Paragraph>
        <LinkButton
          event={{
            eventName:
              'Home Page Creators Minimum Payout Ad Tile Rules Go Shopping Clicked',
            ...tileConfig,
          }}
          href={tileConfig.storeUrl}
        >
          Go Shopping
        </LinkButton>
      </Dialog>
    </IfFeatureEnabled>
  )
}
