import { YStack, YStackProps } from 'tamagui'
import { getConfigForSize } from './Page'

export type SectionProps = YStackProps & {
  containerSize?: 'sm' | 'md' | 'fluid'
}

/**
 * Only use this for marketing pages. Do not use it inside of the app. Use the spacing from the <Page /> component
 * instead and use SectionBreakout if you need to go outside of those bounds.
 * @deprecated!
 */
export const Section = ({
  containerSize = 'fluid',
  ...props
}: SectionProps) => {
  return (
    <YStack
      px="$4"
      $gtMd={{ px: '$8' }}
      {...getConfigForSize(containerSize)}
      {...props}
    />
  )
}
